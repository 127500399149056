import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import { Box, Grid } from '@mui/material';

import iconArrowRight from '../images/donation/long_right.svg';

import ChevronRight from '../images/donation/chevron_right.svg';
import ChevronLeft from '../images/donation/chevron_left.svg';
import BackgroundImage from 'gatsby-background-image';
import SortByDropMenu from '../components/Donation/SortByDropMenu';
import { useFetch } from '../hooks';
import { AppContext } from '../apollo/wrap-root-element';
import {
  getTotalPages,
  isDateFromLastThirtyDaysDate,
  isDateLastThreeMonths,
  isDatesThisYear,
  paginatedItem,
} from '../utils/donate';
import { Link, graphql, navigate } from 'gatsby';
import { getListDonationProjectForSelect } from '../utils/functions';
import ComboBoxSelect from '../components/Form/ComboBox';
import { currencySymbols } from '../modules/timeline/components/EnterButton/utils';

const DonationHistoryPage = (props) => {
  const {
    data: { bgGive, patreonThumb, donationsDetails, languageDonationPage },
  } = props;
  const [getCurrentUserDonationHistory, allDonationHistory, error, loading] = useFetch('POST');
  const [redoDonationHistory, redoHistoryData, isRedoError, isRedoLoading] = useFetch('POST');
  const [downloadPdf, downloadPdfData, downloadPdfError, isDownloadingPdf] = useFetch('POST');
  const [downloadingId, setDownloadingId] = useState(1);
  const [selectedDonatedProject, setSelectedProject] = React.useState();

  const donationDetailsData = donationsDetails.edges[0].node.data;
  const donationProjects = donationDetailsData.projects;

  const { currentUserDetails } = React.useContext(AppContext);

  const [currentPage, setCurrentPage] = useState(1);

  const [donationHistoryData, setDonationHistoryData] = React.useState([]);
  const pageSize = 10;
  const { donatingProjectId, setDonatingProjectId } = React.useContext(AppContext);

  React.useEffect(() => {
    if (currentUserDetails) {
      getCurrentUserDonationHistory('api/donate/get-customer-donations', {
        body: {
          customerId: currentUserDetails?.customer?.id,
        },
        onSuccess: (res) => {
          setDonationHistoryData(res.data);
        },
        onError: () => {},
      });
    }
  }, [currentUserDetails]);

  const totalPage = getTotalPages(donationHistoryData.length, pageSize);
  const projectsLists = getListDonationProjectForSelect(donationProjects, languageDonationPage.edges[0].node.data.body);

  const onFilterClicked = (searchKey) => {
    if (searchKey) {
      const searchProject = projectsLists.find((item) => item.value === searchKey.value);
      console.log(searchProject, allDonationHistory?.data, 'searchProject');
      const filteredData = allDonationHistory?.data?.filter((item) =>
        searchProject?.childProjectId?.toLowerCase()
          ? item?.donatedProject?.subUid?.toLowerCase() === searchProject?.childProjectId?.toLowerCase()
          : item?.donatedProject?.uid?.toLowerCase() === searchProject?.parentProjectId?.toLowerCase()
      );
      setDonationHistoryData(filteredData);
    }
  };

  const last30DaysClicked = () => {
    const filteredData = donationHistoryData?.filter((item) => isDateFromLastThirtyDaysDate(item.createdAt));
    setDonationHistoryData(filteredData);
  };

  const last3MonthClicked = () => {
    const filteredData = donationHistoryData?.filter((item) => isDateLastThreeMonths(item.createdAt));
    setDonationHistoryData(filteredData);
  };

  const thisYearFilteredClicked = () => {
    const filteredData = donationHistoryData?.filter((item) => isDatesThisYear(item.createdAt));
    setDonationHistoryData(filteredData);
  };

  const orderByDateLatest = () => {
    const orderedByLatestDateData = [
      ...(donationHistoryData || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
    ];
    setDonationHistoryData(orderedByLatestDateData);
  };
  const orderByDateOldest = () => {
    const orderedByOldestDateData = [
      ...(donationHistoryData || []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
    ];
    setDonationHistoryData(orderedByOldestDateData);
  };

  const orderByAmountHigh = () => {
    const orderedByAmountHigh = [...(donationHistoryData || []).sort((a, b) => b.amount - a.amount)];
    setDonationHistoryData(orderedByAmountHigh);
  };

  const orderByAmountLowFirst = () => {
    const orderedByAmountLow = [...(donationHistoryData || []).sort((a, b) => a.amount - b.amount)];
    setDonationHistoryData(orderedByAmountLow);
  };

  const tableData = donationHistoryData ? paginatedItem(donationHistoryData, currentPage, pageSize) : [];

  const downloadPdfHandle = (id) => {
    setDownloadingId(id);
    downloadPdf(`api/donate/export-donations-receipts-pdf/${id}`, {
      onSuccess: (data) => {
        console.log(data);
        const blob = new Blob([new Uint8Array(data.data.data)], { type: 'application/pdf' });

        // Create a temporary URL to the blob
        const url = window.URL.createObjectURL(blob);
        // Create a link element and simulate a click to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Donation_receipts.pdf';
        document.body.appendChild(link);
        link.click();
        // Clean up by revoking the temporary URL
        window.URL.revokeObjectURL(url);
      },
    });
  };
  return (
    <BodyClassName className=" bg-dark donation-history">
      <DefaultLayoutComponent title="Giving History">
        <BackgroundImage
          Tag="section"
          className="page-banner over_lpd-banner bgImage bg-pos-center-top"
          fluid={props?.data?.bgGive?.childImageSharp?.fluid}
          preserveStackingContext
        >
          <div className="overlay" />
        </BackgroundImage>
        <div className="site-main">
          <div className="over_lpd-container   ">
            <div className="wrapper bg-white  section wrapper-xl ">
              <div className="pd-x-md donation-mgmt-section wrapper wrapper-lg">
                <h2 className="donation-history-title">GIVING HISTORY</h2>
                {allDonationHistory?.data?.length === 0 && !loading ? (
                  <>
                    <h4 style={{ textAlign: 'center', marginTop: '124px' }}>No any givings has been made.</h4>

                    <div style={{ marginTop: '72px', display: 'flex', justifyContent: 'center' }}>
                      <Link
                        to="/give"
                        className="btn donation-success-button  btn-md btn-primary-ii btn-submit"
                        type="button"
                      >
                        Explore PROJECTS
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <>
                      <>
                        <div className="search-container">
                          <ComboBoxSelect
                            options={projectsLists}
                            name="projectId"
                            buttonClass={'search-input'}
                            value={selectedDonatedProject}
                            onChange={(value) => {
                              setSelectedProject(value);

                              if (value?.label) {
                                onFilterClicked(value);
                              } else {
                                setDonationHistoryData(allDonationHistory?.data);
                              }
                            }}
                            className="w-full"
                          ></ComboBoxSelect>
                          {/* <button
                            className="btn btn-md search-button  btn-primary-li btn-tour-jumbotron "
                            onClick={onFilterClicked}>
                            <img src={iconArrowRight} />
                          </button> */}
                        </div>
                      </>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          marginTop: '20px',
                        }}
                      >
                        <SortByDropMenu
                          last30DaysClicked={last30DaysClicked}
                          last3MonthClicked={last3MonthClicked}
                          thisYearFilteredClicked={thisYearFilteredClicked}
                          orderByDateLatest={orderByDateLatest}
                          orderByDateOldest={orderByDateOldest}
                          orderByAmountHigh={orderByAmountHigh}
                          orderByAmountLowFirst={orderByAmountLowFirst}
                        />
                      </div>

                      <div className=" table-container ">
                        <table>
                          <thead>
                            <tr>
                              <th>SN</th>
                              <th>Date</th>
                              <th>Name</th>
                              <th>Giving Type</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          {/* Table body */}
                          <tbody
                            style={{
                              textAlign: 'left',
                            }}
                          >
                            {tableData?.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{new Date(item.createdAt).toDateString()}</td>
                                <td>{item.donatedProject.subName || item.donatedProject.name}</td>
                                <td>{item?.donateType?.toUpperCase()}</td>
                                <td>
                                  {' '}
                                  {currencySymbols[item.currency ? item.currency.toUpperCase() : 'USD']}
                                  {item.amount.toFixed(2)}
                                </td>
                                {/* <td>{item.amount}</td> */}
                                <td>
                                  <div className="action-container">
                                    <div>
                                      <button
                                        onClick={() => navigate('/donate-transactions/' + item._id)}
                                        className="btn-unstyled"
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          margin: '0 auto',
                                          gap: '4px',
                                          // color: !selectedMembersIndex.length ? '#999999' : '#0a0a0a',
                                        }}
                                        type="button"
                                      >
                                        <RemoveRedEyeOutlinedIcon />
                                      </button>
                                    </div>{' '}
                                    <div>
                                      <button
                                        className={
                                          'btn-unstyled ' + isDownloadingPdf && downloadingId === item._id
                                            ? ' btn-loading '
                                            : ''
                                        }
                                        onClick={() => downloadPdfHandle(item._id)}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          margin: '0 auto',
                                          gap: '4px',
                                          border: 'none',
                                          backgroundColor: 'transparent',
                                        }}
                                        type="button"
                                      >
                                        {isDownloadingPdf && downloadingId === item._id ? (
                                          <>
                                            {' '}
                                            <span className="spinner"></span>
                                          </>
                                        ) : (
                                          <FileDownloadOutlinedIcon />
                                        )}
                                      </button>
                                    </div>
                                    {item?.donateType !== 'monthly' ? (
                                      <button
                                        className="sort-by-button"
                                        onClick={() => {
                                          if (item.donatedProject.subName) {
                                            setDonatingProjectId(item.donatedProject.subUid);
                                            navigate('/language-donations-page#donate-box');
                                            return;
                                          }
                                          console.log(donationProjects, item?.donatedProject, 'donationProjects');
                                          const project = donationProjects.find(
                                            (pr) => pr?.detailsPage.id === item?.donatedProject?.uid
                                          );

                                          if (project) {
                                            navigate(`/donate/${project?.detailsPage?.uid}#donate-box`);
                                          }

                                          // redoDonationHistory('api/donate/redo-donations/' + item._id, {
                                          //   onSuccess: (res) => {
                                          //     if (res.status) {
                                          //     }
                                          //   },
                                          // });
                                        }}
                                      >
                                        {isRedoLoading ? <span className="spinner"></span> : 'Redo'}
                                      </button>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                    {allDonationHistory?.data.length !== 0 ? (
                      <>
                        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                          <Grid item>
                            <div className="btn-group-pagination">
                              {currentPage !== 1 ? (
                                <button
                                  className="btn  btn-table-pagination  btn-outline  "
                                  onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                  <img src={ChevronLeft} />
                                  <span>Previous</span>
                                </button>
                              ) : null}

                              {currentPage < totalPage && totalPage !== 0 ? (
                                <button
                                  className="btn  btn-table-pagination btn-outline  "
                                  onClick={() => setCurrentPage(currentPage + 1)}
                                >
                                  <img src={ChevronRight} />
                                  <span>Next</span>
                                </button>
                              ) : null}
                            </div>
                          </Grid>

                          <Grid item>
                            <p>Page 1 of {totalPage}</p>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgGive: file(relativePath: { eq: "donate_flag.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            body {
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  continent
                }
              }
            }
          }
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            headingDescriptions: heading_descriptions {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              goal
              shortDescriptions: short_descriptions {
                text
              }
              featured
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DonationHistoryPage;
